module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-chakra-ui/gatsby-browser.js'),
      options: {"plugins":[],"isResettingCSS":true,"isUsingColorMode":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/julian/WebstormProjects/Maclic/temp/Maclic-Web/src/i18n","languages":["en","es"],"defaultLanguage":"es","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"563080854321622"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
