import React from "react";

export const GlobalStateContext = React.createContext(null);
export const GlobalDispatchContext = React.createContext(null);

const initialState = {
  openDialog: false,
  message: null,
};

export const actions = {
  toggleDialog: "toggleDialog",
  openDialog: "openDialog",
  closeDialog: "closeDialog",
  setDialogMessage: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.toggleDialog: {
      let { message } = state;
      if (state.openDialog) {
        message = null;
      }
      return {
        ...state,
        openDialog: !state.openDialog,
        message,
      };
    }
    case actions.openDialog: {
      return {
        ...state,
        openDialog: true,
      };
    }
    case actions.closeDialog: {
      return {
        ...state,
        openDialog: false,
        message: null,
      };
    }
    case actions.setDialogMessage: {
      return {
        ...state,
        message: action.message,
        openDialog: true,
      };
    }
    default:
      throw new Error("Bad Action Type");
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider;
