// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-service-js": () => import("./../../../src/pages/home-service.js" /* webpackChunkName: "component---src-pages-home-service-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-places-js": () => import("./../../../src/pages/places.js" /* webpackChunkName: "component---src-pages-places-js" */),
  "component---src-pages-rolda-burger-index-js": () => import("./../../../src/pages/rolda-burger/index.js" /* webpackChunkName: "component---src-pages-rolda-burger-index-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-place-js": () => import("./../../../src/templates/place.js" /* webpackChunkName: "component---src-templates-place-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */)
}

